.header-slider-button {
    display: block;
}

.header-nav-menu {
    display: none;
}

.header-theme-switch {
    margin: auto;
}

@media (min-width: 480px) {
    .header-slider-button {
        display: none;
    }

    .header-nav-menu {
        display: inline-flex;
    }

    .header-theme-switch {
        margin: auto 8px;
    }
}

.header {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    border-radius: 0px 0px 8px 8px;
    overflow: hidden;
    padding: 0px 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);

    .header-logo-mask {
        position: absolute;
        width: 75%;
        height: 50%;
        top: 2px;
        mask-size: contain;
        mask-repeat: no-repeat;
        margin: 16px;
    }
}

.footer {
    border-radius: 8px 8px 0px 0px;
}

.footer-div {
    gap: 8px;
    flex-direction: column;
    display: flex;
}

.footer-div .footer-right-div {
    flex: 1;
    text-align: start;
}

@media (min-width: 480px) {
    .footer-div {
        flex-direction: row;
    }

    .footer-div .footer-right-div {
        text-align: end;
    }
}

.footer .copyright {
    padding-top: 8px;
    font-size: 11px;
    text-align: center;
}

.footer .copyright a {}